import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import {
  ArchiveLayoutBlogs,
  ArchiveLayoutCategories,
  ArchiveLayoutJobs,
  ArchiveLayoutMaterials,
  ArchiveLayoutSubPages,
  withGlobals,
} from '../components'

export const Archive = ({
  contentfulArchive,
  allContentfulBlog,
  allContentfulCategory,
  allContentfulJob,
  allContentfulMaterial,
  pageContext,
}) => {
  const checkId = (id) =>
    contentfulArchive.contentful_id === id

  // MATERIALS ARCHIVE LAYOUT
  if (checkId('wMUu00or8N4EXHm2zKPjk'))
    return (
      <ArchiveLayoutMaterials
        data={{ contentfulArchive, allContentfulMaterial }}
      />
    )

  // BLOG ARCHIVE LAYOUT
  if (checkId('6KOzmkANNflM8tn9DSJKPY')) {
    return (
      <ArchiveLayoutCategories
        data={{ contentfulArchive, allContentfulCategory }}
      />
    )
  }

  if (checkId('56tdvBGBFhDyF7Hd1xqdSQ')) {
    return (
      <ArchiveLayoutJobs
        data={{ contentfulArchive, allContentfulJob }}
      />
    )
  }

  if (checkId('4mWjzTNdnMQdilQzOBIgs5')) {
    return (
      <ArchiveLayoutBlogs
        data={{
          contentfulArchive,
          allContentfulBlog,
          perPage: pageContext.limit,
        }}
      />
    )
  }

  return <ArchiveLayoutSubPages data={contentfulArchive} />
}

Archive.propTypes = {
  // eslint-disable-next-line
  contentfulArchive: PropTypes.object.isRequired,
  // eslint-disable-next-line
  allContentfulBlog: PropTypes.object,
  // eslint-disable-next-line
  allContentfulCategory: PropTypes.object,
  // eslint-disable-next-line
  allContentfulJob: PropTypes.object,
  // eslint-disable-next-line
  allContentfulMaterial: PropTypes.object,
  // eslint-disable-next-line
  pageContext: PropTypes.object,
}

export const query = graphql`
  query getContentfulArchiveById(
    $contentful_id: String
    $node_locale: String
    $skip: Int
    $limit: Int
  ) {
    contentfulArchive(
      contentful_id: { eq: $contentful_id }
      node_locale: { eq: $node_locale }
    ) {
      relativePath
      contentful_id
      title
      slug
      hero {
        title
        subtitle
        image {
          fluid {
            src
          }
        }
      }
      description {
        json
      }
      subPages {
        relativePath
        hero {
          title
          subtitle
          image {
            fluid {
              src
            }
          }
        }
      }
      seo {
        title
        description {
          description
        }
      }
    }

    allContentfulBlog(
      skip: $skip
      limit: $limit
      filter: { node_locale: { eq: $node_locale } }
    ) {
      nodes {
        title
        image {
          fluid {
            srcSet
          }
        }
        relativePath
        node_locale
        categories {
          featuredImage {
            fluid {
              srcSet
            }
          }
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
        currentPage
        totalCount
      }
    }

    allContentfulCategory(
      filter: { node_locale: { eq: $node_locale } }
    ) {
      nodes {
        name
        relativePath
        featuredImage {
          fluid {
            srcSet
          }
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
        currentPage
        totalCount
      }
    }

    allContentfulMaterial(
      filter: { node_locale: { eq: $node_locale } }
    ) {
      nodes {
        name
        category
      }
    }

    allContentfulJob(
      filter: { node_locale: { eq: $node_locale } }
    ) {
      nodes {
        title
        relativePath
        node_locale
      }
    }

    hreflang: allContentfulArchive(
      filter: {
        contentful_id: { eq: $contentful_id }
        node_locale: { ne: $node_locale }
      }
    ) {
      nodes {
        node_locale
        relativePath
      }
    }
  }
`

export default withGlobals(Archive)
